import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Head99min from '@/components/Head99min/Head99min';
import ScrollProgress from '@/components/scroll-progress/ScrollProgress';
import MainLayout from '@/layouts/main/MainLayout';
import {
  HomeCO2,
  HomeHero,
  HomeHitos,
  HomeLeadingCompanies,
  HomeMediaPresence,
  HomePartners,
  HomeTechnologyExperience,
} from '@/sections/home';
import { countriesWithOutCompanies } from '@/sections/home/HomeLeadingCompanies/constants';
import HomeMap from '@/sections/home/HomeMap/HomeMap';
import { useLayoutStore } from '@/stores/layout/layout.store';

import indexImage from 'public/assets/illustrations/byCountry/MX/home.webp';

import type { ReactElement } from 'react';

HomePage.getLayout = (page: ReactElement) => <MainLayout> {page} </MainLayout>;

export default function HomePage() {
  const country = useLayoutStore((state) => state.country);
  const { t } = useTranslation();

  const title = t('META.HEAD.BASE');
  const titleCL = t('META.HEAD.BASECL');
  const description = t('META.DESCRIPTION.BASE');

  return (
    <>
      <Head99min
        title={country === 'CL' ? titleCL : title}
        description={description}
        image={indexImage}
        type="website"
        url="https://99minutos.com/"
      />
      <Box>
        <ScrollProgress />
        <HomeHero />
        <HomeTechnologyExperience />
        {!countriesWithOutCompanies.includes(country) && <HomeLeadingCompanies />}
        <HomeHitos />
        <HomeMap />
        <HomeCO2 />
        <HomeMediaPresence />
        <HomePartners />
      </Box>
    </>
  );
}
