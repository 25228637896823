// @ts-nocheck
import Image from 'next/image';

import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionViewport, varFade } from '@/components/animate';
import { useLayoutStore } from '@/stores/layout/layout.store';
import { TitleTextMiddle } from '@/theme/OwnGlobalStylesComponents/StyleText';

import { hitosByCountries } from './constants';
import {
  ContentBox,
  ParentBox,
  SecondaryTitleH6,
  StyledContent,
  StyledRoot,
  TitleBox,
  TitleContainer,
  TotalAmountText,
} from './styles';

export default function HomeHitos() {
  return (
    <StyledRoot component={MotionViewport}>
      <ParentBox>
        <TitleBox>
          <Title />
        </TitleBox>

        <ContentBox>
          <Content />
        </ContentBox>
      </ParentBox>
    </StyledRoot>
  );
}

function Title() {
  const { t } = useTranslation();
  return (
    <m.div variants={varFade().inUp}>
      <TitleTextMiddle>
        {t('FINANCIAL_HIGHLIGHTS.OUR')} <strong>{t('FINANCIAL_HIGHLIGHTS.HIGHLIGHTS')}</strong>
      </TitleTextMiddle>
    </m.div>
  );
}

function Content() {
  const country = useLayoutStore((state) => state.countryName);
  const countryCode = useLayoutStore((state) => state.country);
  const hitos =
    hitosByCountries[country] != null ? hitosByCountries[country] : hitosByCountries['México'];
  const { t } = useTranslation();

  return (
    <StyledContent>
      <m.div variants={varFade().inUp}>
        <TitleContainer>
          <Image
            src="/assets/icons/home/hitos/Icono_paquetesentregados_hitos.svg"
            width={50}
            height={50}
            alt="paquetes-entregados"
            loading="lazy"
          />
          <SecondaryTitleH6>{t('FINANCIAL_HIGHLIGHTS.DELIVERED_PACKAGES')}</SecondaryTitleH6>
        </TitleContainer>

        <TotalAmountText>{hitos.packagesDelivered}</TotalAmountText>
      </m.div>

      <m.div variants={varFade().inUp}>
        <TitleContainer>
          <Image
            src="/assets/icons/home/hitos/icono_shipping_hitos.svg"
            width={50}
            height={50}
            alt="shipping"
          />
          <SecondaryTitleH6>{t('FINANCIAL_HIGHLIGHTS.STORES_USE_OUR_SHIPPING')}</SecondaryTitleH6>
        </TitleContainer>

        <TotalAmountText>{hitos.shipping}</TotalAmountText>
      </m.div>

      <m.div variants={varFade().inUp}>
        {countryCode === 'MX' && (
          <>
            <TitleContainer>
              <Image
                src="/assets/icons/home/hitos/icono_punto99_hitos.svg"
                width={50}
                height={50}
                alt="punto99"
              />
              <SecondaryTitleH6>{t('FINANCIAL_HIGHLIGHTS.ACTIVE_POINTS')}</SecondaryTitleH6>
            </TitleContainer>

            <TotalAmountText>{hitos.point99}</TotalAmountText>
          </>
        )}
      </m.div>
    </StyledContent>
  );
}
