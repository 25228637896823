import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import MediaPresence from '@/sections/blog/MediaPresence/MediaPresence';
import { TitleTextMiddle } from '@/theme/OwnGlobalStylesComponents/StyleText';

function HomeMediaPresence() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5rem',
        gap: 4,
      }}
    >
      <Box>
        <TitleTextMiddle>
          {t('MEDIA_PRESENCE.TITLE')} <strong>{t('MEDIA_PRESENCE.TITLE_STRONG')}</strong>
        </TitleTextMiddle>
      </Box>

      <MediaPresence />
    </Box>
  );
}

export default HomeMediaPresence;
