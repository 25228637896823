import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export const StyledRoot = styled(Box)(({ theme }) => ({
  marginBottom: '3.62rem',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '3.43rem',
  },
}));

export const BoxGeneral = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    margin: '0 auto',
  },
}));

export const StyledDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    flexWrap: 'wrap',
    width: '35%',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'center',
    padding: '0',
  },
  [theme.breakpoints.up('xl')]: {
    width: '35%',
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: '3.5rem',
    textAlign: 'left',
    width: '90%',
    margin: '0 auto',
  },
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  display: 'inherit',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    width: '60%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
  },
}));
