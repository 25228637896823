// @ts-nocheck
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type { Theme } from '@mui/material/styles';

export const StyledDescription = styled('div')(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(),
  height: '100%',
}));

export const BoxDescription = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '80vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '95%',
    gap: '0.7rem',
    marginTop: '1rem',
  },
}));
export const BoxSubDescription = styled(Box)(() => ({
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

export const TotalAmountText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const BoxResponsive = styled(Box)(() => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1rem',
  marginBottom: '3.45rem',
}));
