import { keyframes, styled } from '@mui/system';

export const StyledRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  marginBottom: '5.62rem',
  [theme.breakpoints.up('md')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '0 auto 3.42rem',
  },
}));

export const CompaniesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  padding: '2rem',
}));

export const Slider = styled('div')`
  max-width: 75rem;
  height: 100px;
  display: flex;
  overflow: hidden;
`;

export const SliderTrack = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  animation: `${SlideShow} 20s linear infinite`,
  img: {
    padding: '0 25px 0 25px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '25%',
      maxHeight: '25%',
    },
  },
}));

const SlideShow = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform:  translateX(-100%);
  }
`;
