// @ts-nocheck

import Image from 'next/image';

import { Container } from '@mui/material';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionViewport, varFade } from '@/components/animate';
import { BodyText, TitleTextH4 } from '@/theme/OwnGlobalStylesComponents/StyleText';

import { BoxGeneral, StyledDescription, StyledRoot } from './styles';

import dashboardImage from 'public/assets/images/home/dashboard99.webp';

export default function HomeTechnologyExperience() {
  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <BoxGeneral>
          <Description />
          <Image src={dashboardImage} width={716} style={{ height: 'auto' }} alt="Dashboard99" />
        </BoxGeneral>
      </Container>
    </StyledRoot>
  );
}

function Description() {
  const { t } = useTranslation();

  return (
    <StyledDescription>
      <m.div variants={varFade().inUp}>
        <TitleTextH4>
          {t('ACCESS.TITLE_1')}
          <strong> {t('ACCESS.TITLE_2')} </strong>
        </TitleTextH4>
      </m.div>

      <m.div variants={varFade().inUp}>
        <BodyText pt={3.5}>
          {t('ACCESS.DESCRIPTION_1')}
          <br />
          {t('ACCESS.DESCRIPTION_2')}
        </BodyText>
      </m.div>
    </StyledDescription>
  );
}
