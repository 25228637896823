// @ts-nocheck

import Image from 'next/image';

import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionContainer, varFade } from '@/components/animate';
import useResponsive from '@/hooks/useResponsive';
import { useLayoutStore } from '@/stores/layout/layout.store';
import {
  SubtitleBody2,
  TitleH5WhiteBold,
  TitleTextH4,
} from '@/theme/OwnGlobalStylesComponents/StyleText';

import { ImageBox, ProductContainer, StyledDescription } from './styles';

import CO2Image from 'public/assets/images/home/pic_co2free.webp';

const HomeCO2 = () => {
  const isDesktop = useResponsive('up', 'md');

  const country = useLayoutStore((state) => state.country);

  return (
    <>
      {country === 'MX' && (
        <ProductContainer component={MotionContainer}>
          <ImageBox>
            <Image src={CO2Image} width={isDesktop ? 511 : 405} alt="co2free" loading="lazy" />
          </ImageBox>
          <Description />
        </ProductContainer>
      )}
    </>
  );
};

const Description = () => {
  const { t } = useTranslation();

  return (
    <StyledDescription>
      <m.div variants={varFade().in}>
        <TitleTextH4>
          {t('ENVIRONMENTAL_MEASURES.HOME_SECTION.TITLE_ONE')}
          <strong>{t('ENVIRONMENTAL_MEASURES.HOME_SECTION.TITLE_TWO_STRONG')}</strong>
        </TitleTextH4>
      </m.div>

      <m.div variants={varFade().in}>
        <SubtitleBody2 margin={'1.5rem 0'}>
          {t('ENVIRONMENTAL_MEASURES.HOME_SECTION.DESCRIPTION')}
        </SubtitleBody2>
      </m.div>

      <m.div variants={varFade().in}>
        <TitleH5WhiteBold>{t('ENVIRONMENTAL_MEASURES.HOME_SECTION.SUBTITLE_ONE')}</TitleH5WhiteBold>
      </m.div>
    </StyledDescription>
  );
};

export default HomeCO2;
