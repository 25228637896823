import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface StyledCardProps {
  index: number;
}

export const GeneralContainer = styled(Box)(({ theme }) => ({
  paddingTop: '5rem',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'relative',

  // Mobile
  [theme.breakpoints.down('md')]: {
    paddingTop: '2rem',
  },
}));

export const HiddenMobile = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
  width: 0,
}));

export const TitleH4 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightRegular,

  // Desktop
  [theme.breakpoints.up('lg')]: {
    top: '10rem',
  },

  // Mobile
  [theme.breakpoints.down('md')]: {
    bottom: '50vh',
  },
}));

export const ItemCarrousel = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
  padding: '3rem',
  height: '70vh',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '1rem',
    height: '75vh',
  },
}));

export const StyledCard = styled(Card)<StyledCardProps>(({ theme, index }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  textAlign: 'center',
  width: '100%',
  padding: theme.spacing(0, 5),
  boxShadow: index === 0 ? theme.customShadows.z16 : 'none',
  height: 400,

  [theme.breakpoints.down('md')]: {
    padding: '2.5rem 0.5rem',
    paddingBottom: '0rem',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '37.5rem',
  },
}));

export const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  textAlign: 'left',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '40vw',
  },
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
}));
