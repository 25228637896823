const mexPackage = 35000000;
const mexPackageDailyIncrease = 31000;

const colPackage = 10000000;
const colPackageDailyIncrease = 1600;

const chlPackage = 8500000;
const chlPackageDailyIncrease = 2500;

const perPackage = 20000000;
const perPackageDailyIncrease = 1300;

const globalPackage = mexPackage + colPackage + chlPackage + perPackage;
const dailyPackageIncrease = 36000;

const mexShipping = 50000;
const mexDailyShippingIncrease = 50;

const colShipping = 9000;
const colDailyShippingIncrease = 10;

const chlShipping = 400;
const chlDailyShippingIncrease = 1;

const perShipping = 8000;
const perDailyShippingIncrease = 10;

const globalShipping = mexShipping + colShipping + chlShipping + perShipping;
const dailyShippingIncrease = 80;

const mexPoints = 1500;
const mexDailyPointsIncrease = 5;

const mexCO2 = 15000;
const mexCO2DailyIncrease = 1;

const startDate = new Date(`2023-06-01`);

// Calculate the total value with the given quantity and increase per day
const calculateValue = (quantity: number, increase: number): string => {
  const todayDate = new Date();
  const days = numberOfDaysBetween(startDate, todayDate);
  const total = quantity + days * increase;
  return formatNumber(total);
};

// Format a number to a string with dots as thousands separator
const formatNumber = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `.`);
};

// Returns the number of days between two dates (Integer)
const numberOfDaysBetween = (startDate: Date, endDate: Date): number => {
  const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
};

export const hitosByCountries = {
  México: {
    packagesDelivered: `+ ${calculateValue(mexPackage, mexPackageDailyIncrease)}`,
    shipping: `+ ${calculateValue(mexShipping, mexDailyShippingIncrease)}`,
    point99: `+ ${calculateValue(mexPoints, mexDailyPointsIncrease)}`,
    point99_2: `+${calculateValue(mexPoints, mexDailyPointsIncrease)}`,
    point99_3: `${calculateValue(mexPoints, mexDailyPointsIncrease)}`,
    co2: `+ ${calculateValue(mexCO2, mexCO2DailyIncrease)}`,
  },
  Colombia: {
    packagesDelivered: `+ ${calculateValue(colPackage, colPackageDailyIncrease)}`,
    shipping: `+ ${calculateValue(colShipping, colDailyShippingIncrease)}`,
  },
  Chile: {
    packagesDelivered: `+ ${calculateValue(chlPackage, chlPackageDailyIncrease)}`,
    shipping: `+ ${calculateValue(chlShipping, chlDailyShippingIncrease)}`,
  },
  Perú: {
    packagesDelivered: `+ ${calculateValue(perPackage, perPackageDailyIncrease)}`,
    shipping: `+ ${calculateValue(perShipping, perDailyShippingIncrease)}`,
  },
  Global: {
    packagesDelivered: `+ ${calculateValue(globalPackage, dailyPackageIncrease)}`,
    shipping: `+ ${calculateValue(globalShipping, dailyShippingIncrease)}`,
  },
};
