// @ts-nocheck
import { forwardRef, useMemo, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { m, useMotionValueEvent, useScroll } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionViewport, varFade } from '@/components/animate';
import ScrollDots from '@/components/scrollDots/ScrollDots';
import useResponsive from '@/hooks/useResponsive';
import { useLayoutStore } from '@/stores/layout/layout.store';
import {
  StrongGreen,
  TitleTextH4,
  TitleTextH6Green,
} from '@/theme/OwnGlobalStylesComponents/StyleText';

import { CARDS } from './constants';
import {
  GeneralContainer,
  HiddenMobile,
  ItemCarrousel,
  StyledCard,
  TextBox,
  TitleH4,
} from './styles';

import type { CardUniverse99 } from './constants';

interface UniverseCardProps {
  card: CardUniverse99;
  index: number;
}

const determineActiveCard = (progress: number, totalCards: number) => {
  const minCard = 0;
  const maxCard = totalCards - 1;

  const extraSizeFirstCard = 0.063;
  const firstCardSize = 1 / (totalCards + extraSizeFirstCard);

  let activeCard;

  if (progress <= firstCardSize) {
    activeCard = minCard;
  } else if (progress >= 1 - firstCardSize / 2) {
    activeCard = maxCard;
  } else {
    const normalizedProgress = (progress - firstCardSize / 2) / (1 - firstCardSize);
    const cardRange = maxCard - minCard;
    activeCard = Math.floor(normalizedProgress * cardRange) + minCard;
  }

  return activeCard;
};

const Universe99 = () => {
  const country = useLayoutStore((state) => state.country);
  const [activeCard, setActiveCard] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<Array<HTMLDivElement | null>>([]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end end'],
  });

  const countryCards = useMemo(
    () => CARDS.filter((card) => card.allowCountries.includes(country)),
    [country],
  );

  const totalCards = useMemo(() => countryCards.length, [countryCards]);

  const scrollToCard = (index: number) => {
    const increment = 55;

    const card = cardsRef.current[index];
    if (card == null) return;
    const originalPosition = card.style.position;

    card.style.position = 'static';
    const offsetTop = card.offsetTop;
    const elementHeight = card.offsetHeight;
    const offset = increment + offsetTop + elementHeight + elementHeight * 0.1;
    card.style.position = originalPosition;

    window.scrollTo({ top: offset, behavior: 'smooth' });
  };

  // Detect active card
  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    const activeCard = determineActiveCard(latest, totalCards);
    setActiveCard(activeCard);
  });

  return (
    <GeneralContainer component={MotionViewport}>
      <div ref={containerRef}>
        {countryCards.map((card, index) => (
          <UniverseCards
            card={card}
            index={card.id}
            key={index}
            ref={(el) => (cardsRef.current[index] = el)}
          />
        ))}
      </div>

      <HiddenMobile>
        <ScrollDots quantity={totalCards} activeDot={activeCard} scrollTo={scrollToCard} />
      </HiddenMobile>
    </GeneralContainer>
  );
};

const UniverseCards = forwardRef<HTMLDivElement, UniverseCardProps>(function UniverseCards(
  { card, index },
  forwardRef,
) {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0.5);
  const ref = useRef<HTMLDivElement>(null);

  const { title, subtitle, descriptionTitle, description, icon, image } = card;
  const isDesktop = useResponsive('up', 'md');

  const stickyMobile = useMemo(() => (index === 0 ? 7.5 : 9.25), [index]);
  const stickyDesktop = useMemo(() => (index === 0 ? 7.5 : 10.2), [index]);
  const stickyBase = useMemo(
    () => (isDesktop ? stickyDesktop : stickyMobile),
    [isDesktop, stickyMobile, stickyDesktop],
  );

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  });

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    const limit = 0.5;

    let inAmount: number;
    let outAmount: number;

    const inAmountDesktop = 0.5;
    const outAmountDesktop = 0.25;

    const inAmountMobile = 0.5;
    const outAmountMobile = 0.05;

    if (isDesktop) {
      inAmount = inAmountDesktop;
      outAmount = outAmountDesktop;
    } else {
      inAmount = inAmountMobile;
      outAmount = outAmountMobile;
    }

    if (latest > limit && amount !== outAmount) {
      setAmount(outAmount);
    } else if (latest < limit && amount !== inAmount) {
      setAmount(inAmount);
    }
  });

  return (
    <m.div
      initial="initial"
      whileInView="animate"
      variants={varFade().inAndOut}
      ref={forwardRef}
      style={{ position: 'sticky', top: `${stickyBase}rem` }}
      viewport={{ once: false, amount: 0.1 }}
    >
      <div ref={ref}>
        {index === 0 && (
          <TitleH4>
            {t('SLIDER.TITLE_1')} <StrongGreen>{t('SLIDER.TITLE_2')}</StrongGreen>
          </TitleH4>
        )}

        <ItemCarrousel>
          <StyledCard index={index}>
            <Grid container height="100%" width="100%">
              <Grid xs={12} md display="flex" alignItems="center">
                <TextBox>
                  <img src={icon} alt="icon" />

                  <TitleTextH6Green mt={2}>{t(descriptionTitle)}</TitleTextH6Green>

                  <TitleTextH4 mt={1}>
                    <strong>{t(title)} </strong>
                    {t(subtitle)}
                  </TitleTextH4>

                  <Typography mt={1} mb={2}>
                    {t(description)}
                  </Typography>
                </TextBox>
              </Grid>

              <Grid xs={12} md="auto" display="flex" alignItems="end" justifyContent="center">
                <img
                  src={`/assets/images/home/steps/${image.path}`}
                  width={isDesktop ? image.width : image.width / 1.3}
                  height={isDesktop ? image.height : image.height / 1.3}
                  alt={`Universe cards step-${index}`}
                />
              </Grid>
            </Grid>
          </StyledCard>
        </ItemCarrousel>
      </div>
    </m.div>
  );
});

export default Universe99;
