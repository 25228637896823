export const companies = {
  MX: [
    {
      path: 'Liverpool',
    },
    {
      path: 'Dropi',
    },
    {
      path: 'Ikea',
    },
    {
      path: 'Justo',
    },
    {
      path: 'Mercadolibre',
    },
    {
      path: 'Sodimac',
    },
  ],
  CL: [
    {
      path: 'Claro',
    },
    {
      path: 'Puma',
    },
    {
      path: 'Entel',
    },
    {
      path: 'Herbalife',
    },
    {
      path: 'Cencosud',
    },
    {
      path: 'Nespresso',
    },
    {
      path: 'Mercadolibre',
    },
    {
      path: 'Nike',
    },
  ],
};

export const countriesWithOutCompanies = ['CO', 'PE'];
