// @ts-nocheck
import Container from '@mui/material/Container';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionViewport, varFade } from '@/components/animate';
import { useLayoutStore } from '@/stores/layout/layout.store';
import { TitleTextMiddle } from '@/theme/OwnGlobalStylesComponents/StyleText';

import { companies } from './constants';
import { CompaniesContainer, LogoContainer, Slider, SliderTrack, StyledRoot } from './styles';

interface SliderCompaniesProps {
  countryCompanies: Company[];
}
interface Company {
  path: string;
}

export default function HomeLeadingCompanies() {
  const country = useLayoutStore((state) => state.country);
  const countryCompanies = companies[country];
  const { t } = useTranslation();

  return (
    <>
      <StyledRoot>
        <Container component={MotionViewport}>
          <m.div variants={varFade().inUp}>
            <TitleTextMiddle mb={4}>
              {t('ENTERPRISE.TITLE_1')}
              <strong>{t('ENTERPRISE.TITLE_2')} </strong>
            </TitleTextMiddle>
          </m.div>

          <m.div variants={varFade().inUp}>
            <CompaniesContainer>
              {countryCompanies?.length > 5 ? (
                <SliderCompanies countryCompanies={countryCompanies} />
              ) : (
                countryCompanies?.map((company) => (
                  <LogoContainer key={company.path}>
                    <img
                      src={`/assets/icons/home/companies/${company.path}.svg`}
                      alt={company.path}
                    />
                  </LogoContainer>
                ))
              )}
            </CompaniesContainer>
          </m.div>
        </Container>
      </StyledRoot>
    </>
  );
}

const SliderCompanies = ({ countryCompanies }: SliderCompaniesProps) => {
  return (
    <Slider>
      <SliderTrack>
        {countryCompanies?.map((company) => (
          <img
            src={`/assets/icons/home/companies/${company.path}.svg`}
            alt={company.path}
            key={company.path}
          />
        ))}
        {countryCompanies?.map((company) => (
          <img
            src={`/assets/icons/home/companies/${company.path}.svg`}
            alt={company.path}
            key={company.path}
          />
        ))}
      </SliderTrack>
    </Slider>
  );
};
