import Container from '@mui/material/Container';

import usePosts from '@/hooks/contentful/usePosts';
import { getPostValues } from '@/utils/posts';

import { ArticleCard, NoPosts } from '../Articles/Articles';
import { GridContainer, GridItem, LinkCard, LoadingSkeletonPost } from '../Articles/styles';

const MediaPresence = () => {
  const { posts, isLoading } = usePosts();
  const filtered = posts?.filter((post) => post.fields.mediaPresence);

  if (filtered.length === 0) return <NoPosts />;

  // Get only 3 posts
  const postsLimited = filtered.slice(0, 3);

  return (
    <Container>
      <GridContainer container>
        {postsLimited.map((post) => {
          const data = getPostValues(post);

          return (
            <>
              {isLoading ? (
                <LoadingSkeletonPost
                  sx={{ bgcolor: '#DAEDC6' }}
                  animation="pulse"
                  variant="rectangular"
                />
              ) : (
                <GridItem>
                  <LinkCard key={data.postId} href={data.url}>
                    <ArticleCard post={post} />
                  </LinkCard>
                </GridItem>
              )}
            </>
          );
        })}
      </GridContainer>
    </Container>
  );
};

export default MediaPresence;
