export interface CardUniverse99 {
  id: number;
  icon: string;
  title: string;
  subtitle: string;
  image: ImageType;
  descriptionTitle: string;
  description: string;
  allowCountries: string[];
}

interface ImageType {
  path: string;
  width: number;
  height: number;
}

export const CARDS: CardUniverse99[] = [
  {
    id: 0,
    icon: '/assets/icons/home/Icono_paquetesentregados_hitos.svg',
    title: 'SLIDER.INTRO.DESCRIPTION_1',
    subtitle: 'SLIDER.INTRO.DESCRIPTION_2',
    image: { path: 'pic_step_01.webp', width: 504, height: 367 },
    descriptionTitle: 'SLIDER.INTRO.SUBTITLE',
    description: 'SLIDER.INTRO.DESCRIPTION_3',
    allowCountries: ['MX', 'PE', 'CO', 'CL'],
  },
  {
    id: 1,
    icon: '/assets/icons/home/universe/envios99_logo.svg',
    title: 'SLIDER.DELIVERY99.DESCRIPTION_1',
    image: { path: 'pic_step_03.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.DELIVERY99.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.DELIVERY99.SUBTITLE',
    description: 'SLIDER.DELIVERY99.DESCRIPTION_3',
    allowCountries: ['MX', 'PE', 'CL', 'CO'],
  },
  {
    id: 6,
    icon: '/assets/icons/home/universe/tailormade.svg',
    title: 'SLIDER.TAILOR99.DESCRIPTION_1',
    image: { path: 'pic_step_08.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.TAILOR99.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.TAILOR99.SUBTITLE',
    description: 'SLIDER.TAILOR99.DESCRIPTION_3',
    allowCountries: ['MX', 'CO', 'PE', 'CL'],
  },
  {
    id: 2,
    icon: '/assets/icons/home/universe/Logo_fulfill99_animacion.svg',
    title: 'SLIDER.FULFILL99.DESCRIPTION_1',
    subtitle: 'SLIDER.FULFILL99.DESCRIPTION_2',
    image: { path: 'pic_step_02.webp', width: 504, height: 367 },
    descriptionTitle: 'SLIDER.FULFILL99.SUBTITLE',
    description: 'SLIDER.FULFILL99.DESCRIPTION_3',
    allowCountries: ['MX', 'CL'],
  },
  {
    id: 3,
    icon: '/assets/icons/home/universe/Logo_cash99_animacion.svg',
    title: 'SLIDER.CASH99.DESCRIPTION_1',
    image: { path: 'pic_step_04.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.CASH99.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.CASH99.SUBTITLE',
    description: 'SLIDER.CASH99.DESCRIPTION_3',
    allowCountries: ['MX', 'CO'],
  },
  {
    id: 4,
    icon: '/assets/icons/home/universe/Logo_punto99_animacion.svg',
    title: 'SLIDER.PUNTO99.DESCRIPTION_1',
    image: { path: 'pic_step_05.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.PUNTO99.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.PUNTO99.SUBTITLE',
    description: 'SLIDER.PUNTO99.DESCRIPTION_3',
    allowCountries: ['MX'],
  },
  {
    id: 5,
    icon: '/assets/icons/home/universe/ruta99_logo.svg',
    title: 'SLIDER.RUTA99.DESCRIPTION_1',
    image: { path: 'pic_step_06.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.RUTA99.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.RUTA99.SUBTITLE',
    description: 'SLIDER.RUTA99.DESCRIPTION_3',
    allowCountries: ['MX', 'CO', 'PE', 'CL'],
  },
  {
    id: 7,
    icon: '/assets/icons/home/Icono_paquetesentregados_hitos.svg',
    title: 'SLIDER.LAST.DESCRIPTION_1',
    image: { path: 'pic_step_07.webp', width: 504, height: 367 },
    subtitle: 'SLIDER.LAST.DESCRIPTION_2',
    descriptionTitle: 'SLIDER.LAST.SUBTITLE',
    description: 'SLIDER.LAST.DESCRIPTION_3',
    allowCountries: ['MX', 'CO', 'PE', 'CL'],
  },
];
