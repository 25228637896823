import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import type { Theme } from '@mui/material/styles';

export const StyledRoot = styled(Box)(({ theme }: { theme: Theme }) => ({
  marginBottom: '5.62rem',
  background:
    'transparent linear-gradient(180deg, #85C440 0%, #68C072 100%) 0% 0% no-repeat padding-box',
  padding: '5.18rem 0',
  [theme.breakpoints.down('md')]: {
    marginBottom: '3.42rem',
    padding: '2.12rem 0',
  },
}));

export const TitleContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1rem',
}));

export const SecondaryTitleH6 = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.secondary.main,
}));

export const TotalAmountText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h2,
  color: 'white',
  fontWeight: theme.typography.fontWeightMedium,
}));

export const MapContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'center',
  gap: '2rem',
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '3rem',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'left',
  },
}));

export const ParentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(),
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'inherit',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(),
  [theme.breakpoints.up('lg')]: {
    width: '30%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '2.94rem',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWarp: 'wrap',
  },
}));
