// @ts-nocheck
import Link from 'next/link';

import { Container } from '@mui/material';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionViewport, varFade } from '@/components/animate';
import { TitleTextMiddle } from '@/theme/OwnGlobalStylesComponents/StyleText';

import { partners } from './constants';
import { PartnersContainer, StyledContent, StyledRoot } from './styles';

export default function HomePartners() {
  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Description />
      </Container>
    </StyledRoot>
  );
}

function Description() {
  const { t } = useTranslation();
  return (
    <StyledContent>
      <m.div variants={varFade().inDown}>
        <TitleTextMiddle>
          {t('PARTNERS.TITLE_1')}
          <strong>{t('PARTNERS.TITLE_2')}</strong>
        </TitleTextMiddle>
      </m.div>

      <m.div variants={varFade().inDown}>
        <PartnersContainer>
          {partners.map(({ path, href }) => (
            <Link href={href} key={path} target="_blank">
              <img src={`/assets/icons/home/partners/${path}`} alt={path} key={path} />
            </Link>
          ))}
        </PartnersContainer>
      </m.div>
    </StyledContent>
  );
}
