import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRoot = styled('div')(({ theme }) => ({
  margin: '5.62rem 0',
  [theme.breakpoints.down('md')]: {
    margin: '3.43rem 0',
  },
}));

export const StyledContent = styled(Stack)(({ theme }) => ({
  marginBottom: '2rem',
  textAlign: 'center',
  gap: '2rem',
}));

export const PartnersContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  gap: '5.37rem',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0 auto',
    flexWrap: 'wrap',
    width: '90vw',
    gap: '4rem',
  },
}));
