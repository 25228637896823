import Image from 'next/image';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { MotionContainer, varFade } from '@/components/animate';
import useResponsive from '@/hooks/useResponsive';
import { useLayoutStore } from '@/stores/layout/layout.store';
import {
  SubtitleBody2,
  TitleH5,
  TitleTextMiddle,
} from '@/theme/OwnGlobalStylesComponents/StyleText';

import {
  BoxDescription,
  BoxResponsive,
  BoxSubDescription,
  StyledDescription,
  TotalAmountText,
} from './styles';

import coverageImage from 'public/assets/images/map/Cobertura_region.webp';

const countriesWithOutNumber = ['CO', 'CL'];
const HomeMap = () => {
  const isDesktop = useResponsive('up', 'md');
  const country = useLayoutStore((state) => state.country);
  const { t } = useTranslation();

  const handleCountry = () => {
    switch (country) {
      case 'CL':
        return 'https://coverage-map-svg.vercel.app/chile';
      case 'CO':
        return 'https://coverage-map-svg.vercel.app/colombia';
      case 'PE':
        return 'https://coverage-map-svg.vercel.app/peru';
      default:
        return 'https://coverage-map-svg.vercel.app/';
    }
  };

  return (
    <>
      <Container component={MotionContainer}>
        <Box sx={{ textAlign: '-webkit-center' }}>
          <Description />
        </Box>
      </Container>

      {isDesktop ? (
        <Box display="flex" alignItems="center">
          <Box width="65%">
            <iframe
              style={{ height: '710px', border: 'none' }}
              src={handleCountry()}
              width="100%"
              title="coverage map"
            />
          </Box>

          <Box width="35%">
            <Image
              src={coverageImage}
              alt="coverage zones"
              loading="lazy"
              style={{ maxWidth: '28rem', width: '100%', height: 'auto' }}
            />
          </Box>
        </Box>
      ) : (
        <BoxResponsive>
          <Image
            src={coverageImage}
            alt="coverage zones"
            loading="lazy"
            style={{ maxWidth: '36rem', width: '100%', height: 'auto' }}
          />
          <Typography variant="body2" sx={{ px: '1rem' }}>
            <strong>{t('MAP.MAP_RESPONSIVE_1')}</strong>
            {t('MAP.MAP_RESPONSIVE_2')}
          </Typography>
        </BoxResponsive>
      )}
    </>
  );
};

const Description = () => {
  const { t } = useTranslation();
  const isDesktop = useResponsive('up', 'md');
  const country = useLayoutStore((state) => state.country);

  return (
    <StyledDescription>
      <m.div variants={varFade().in}>
        <TitleTextMiddle mb={isDesktop ? '2.6rem' : '1.3rem'}>
          {t(`MAP.TITLE`)}
          <strong>{t(`MAP.TITLE_STRONG`)}</strong>
        </TitleTextMiddle>
      </m.div>

      <m.div variants={varFade().in}>
        <BoxDescription>
          {!countriesWithOutNumber.includes(country) && (
            <BoxSubDescription>
              <TotalAmountText>{t(`MAP.TOTAL_${country}`)}</TotalAmountText>
              <TitleH5>
                <strong>{t('MAP.CITIES')}</strong>
              </TitleH5>
            </BoxSubDescription>
          )}

          <BoxSubDescription>
            <SubtitleBody2 sx={{ textAlign: isDesktop ? 'left' : 'center' }}>
              <strong>{t(`MAP.SUBTITLE_${country}`)}</strong>
              {t(`MAP.SUBTITLE_${country}_2`)}
            </SubtitleBody2>
          </BoxSubDescription>
        </BoxDescription>
      </m.div>
    </StyledDescription>
  );
};

export default HomeMap;
