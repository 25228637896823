// @ts-nocheck

import dynamic from 'next/dynamic';
import { useMemo } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { PATH_TICKET_ZOHODESK_CL } from '@/routes/paths';
import { useLayoutStore } from '@/stores/layout/layout.store';

import { SEASONAL_DEMAND_ENABLED, SEASONAL_DEMAND_ENABLED_CL } from './constants';
import { SeasonalContainerInvisible } from './Header';

const Header = dynamic(() => import('./Header'), { ssr: false });
const Footer = dynamic(() => import('./Footer/Footer'), { ssr: false });

interface Props {
  children?: React.ReactNode;
}

export default function MainLayout({ children }: Props) {
  const { t } = useTranslation();

  const country = useLayoutStore((state) => state.country);
  const headerSizeMD = useMemo(() => {
    if (!SEASONAL_DEMAND_ENABLED || !SEASONAL_DEMAND_ENABLED_CL) return 11;
    if (country === 'MX' || country === 'CL') return 17;
    return 11;
  }, [country]);

  const headerSizeXS = 10;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: headerSizeXS, md: headerSizeMD },
        }}
      >
        {SEASONAL_DEMAND_ENABLED && country === 'MX' && (
          <SeasonalContainerInvisible>{t('LAYOUT.HEADER.SEASONAL')}</SeasonalContainerInvisible>
        )}
        {SEASONAL_DEMAND_ENABLED_CL && country === 'CL' && (
          <SeasonalContainerInvisible sx={{}}>
            <span>
              <b>{t('LAYOUT.HEADER.SEASONAL_CL.HEADER.QUESTION')}</b>&nbsp;
              {t('LAYOUT.HEADER.SEASONAL_CL.HEADER.CS')} &nbsp;
              <a href={PATH_TICKET_ZOHODESK_CL}>{t('LAYOUT.HEADER.SEASONAL_CL.MAIL')}</a>
            </span>
          </SeasonalContainerInvisible>
        )}
        {children}
      </Box>

      <Footer />
    </Box>
  );
}
