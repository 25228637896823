import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const ProductContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  background: 'linear-gradient(45deg, #85C440, #68C072)',
  borderRadius: '250px',
  width: '90%',
  gap: '1rem',
  height: '28rem',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    background: `linear-gradient(180deg, #85C440, #68C072)`,
    flexDirection: 'column',
    width: '50%',
    height: 'auto',
    paddingBottom: '5rem',
    margin: 'o auto',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    paddingBottom: '6rem',
  },
}));

export const StyledDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '45%',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    width: '90%',
  },
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  marginLeft: '-4rem',
  [theme.breakpoints.between('md', 'lg')]: {
    width: '50%',
    marginLeft: '-3.5rem',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0 auto',
    '&& img': {
      height: 'auto',
      margin: '0 auto',
    },
  },
}));
