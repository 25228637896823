import slide01 from 'public/assets/images/home/principalSlider/pic_slide01.png';
import slide02 from 'public/assets/images/home/principalSlider/pic_slide02.png';
import slide03 from 'public/assets/images/home/principalSlider/pic_slide03.png';
import slide04 from 'public/assets/images/home/principalSlider/pic_slide04.png';
import slide05 from 'public/assets/images/home/principalSlider/pic_slide05.png';
import slide06 from 'public/assets/images/home/principalSlider/pic_slide06.png';
import slide01resp from 'public/assets/images/home/principalSliderResponsive/pic_slide01_resp.png';
import slide02resp from 'public/assets/images/home/principalSliderResponsive/pic_slide02_envios_resp.png';
import slide03resp from 'public/assets/images/home/principalSliderResponsive/pic_slide03_tailor_resp.png';
import slide04resp from 'public/assets/images/home/principalSliderResponsive/pic_slide04_fulfill_resp.png';
import slide05resp from 'public/assets/images/home/principalSliderResponsive/pic_slide05_cash_resp.png';
import slide06resp from 'public/assets/images/home/principalSliderResponsive/pic_slide06_punto_resp.png';

// import slide07 from 'public/assets/images/home/principalSlider/pic_slide07.png';
// import slide07resp from 'public/assets/images/home/principalSliderResponsive/pic_slide07_ruta_resp.png';

import type { StaticImageData } from 'next/image';

interface IProducts {
  key: string;
  title: string;
  titleCl?: string;
  leyend?: string;
  description: string;
  descriptionCL?: string;
  imageDesk: StaticImageData;
  imageResp: StaticImageData;
  logo?: string;
  logoActive?: string;
}
type Countries = 'MX' | 'CO' | 'PE' | 'CL';

export const products: Record<string, IProducts> = {
  slide01: {
    key: '99minutos',
    title: 'Logística tan única como tu negocio',
    titleCl: 'Tu partner en envíos',
    description:
      'Paquetería, almacenaje y hasta cobro contra entrega. Explora nuestro universo 99minutos, tu logística en un solo lugar.',
    descriptionCL:
      'Integra tu eCommerce y envía tus productos a todo Chile con envios99. También contamos con servicios de abastecimiento de tienda, flota dedicada y <strong>soluciones logísticas a la medida</strong> de tu negocio con tailor99.',
    imageDesk: slide01,
    imageResp: slide01resp,
  },
  slide02: {
    key: 'envios99',
    title: 'Toma control de tu propia logística',
    description:
      'Con diferentes opciones de envío, tarifas transparentes, rastreo satelital y una plataforma sencilla de usar, comenzar a auto-gestionar tu logística con envios99 es fácil y seguro.​',
    imageDesk: slide02,
    imageResp: slide02resp,
    logo: 'envios99.svg',
    logoActive: 'envios99-active.svg',
  },
  slide03: {
    key: 'tailor99',
    title: 'Soluciones únicas diseñadas a la medida',
    description:
      'Un servicio 100% personalizable que permite a nuestros clientes diseñar desde cero una solución logística que se adapte a sus necesidades sin importar de qué tan únicas sea tu industria.',
    imageDesk: slide03,
    imageResp: slide03resp,
    logo: 'tailor99.svg',
    logoActive: 'tailor99-active.svg',
  },
  slide04: {
    key: 'fulfill99',
    leyend: '¡PRÓXIMAMENTE!',
    title: 'Fulfillment flexible e integral',
    titleCl: 'Fulfillment flexible para tu negocio',
    description:
      'Nos conectamos a tu tienda, recolectamos, almacenamos, empacamos, entregamos y hasta cobramos contra entrega todas tus ventas de ecommerce.',
    descriptionCL:
      'Nos hacemos cargo de tu proceso logístico de acuerdo a tus necesidades. Almacenamos, preparamos y entregamos tus pedidos ecommerce a cliente final.',
    imageDesk: slide04,
    imageResp: slide04resp,
    logo: 'fulfill99.svg',
    logoActive: 'fulfill99-active.svg',
  },
  slide05: {
    key: 'cash99',
    title: 'Cobra en efectivo contra entrega',
    description:
      'Crece tus ventas ofreciendo a tus clientes la posibilidad de pagar en efectivo sus compras al momento de entrega.',
    imageDesk: slide05,
    imageResp: slide05resp,
    logo: 'cash99.svg',
    logoActive: 'cash99-active.svg',
  },
  slide06: {
    key: 'punto99',
    title: 'La red de puntos de recolección y entrega más grande de México',
    description:
      'Nos aliamos con más de 2mil negocios locales a lo largo de México para ofrecer la red más robusta de puntos de recolección y entrega del país.',
    imageDesk: slide06,
    imageResp: slide06resp,
    logo: 'punto99.svg',
    logoActive: 'punto99-active.svg',
  },
  slide07: {
    key: 'envios99_CL',
    title: 'Envía tus productos a todo Chile',
    description:
      'Diferentes modalidades de <b>envíos a todo Chile.</b> Gestiona y monitorea tus envíos a través de una plataforma sencilla que se integra a tu negocio.',
    imageDesk: slide02,
    imageResp: slide02resp,
    logo: 'envios99.svg',
    logoActive: 'envios99-active.svg',
  },
  slide08: {
    key: 'tailor99_CL',
    title: 'Soluciones diseñadas para tu negocio',
    description:
      'Logística a la medida de tu negocio. Flota Dedicada, Abastecimiento de Tiendas, Productos Especiales y servicios personalizados.',
    imageDesk: slide03,
    imageResp: slide03resp,
    logo: 'tailor99.svg',
    logoActive: 'tailor99-active.svg',
  },
  /*
  slide07: {
    key: 'ruta99',
    title: 'Optimiza y monitorea tu flotilla',
    description:
      'Con nuestros SAAS de optimización de flotillas y rutas alcanza hasta un 30% de disminución en costos logísticos y 70% de ahorro en el tiempo de planificación.',
    descriptionCL:
      'Con nuestros SAAS de optimización de flota y rutas alcanza hasta un 30% de disminución en costos logísticos y 70% de ahorro en el tiempo de planificación.',
    imageDesk: slide07,
    imageResp: slide07resp,
    logo: 'ruta99.svg',
    logoActive: 'ruta99-active.svg',
  },
  */
};

export const productByCountry: Record<Countries, IProducts[]> = {
  MX: [
    products.slide01,
    products.slide02,
    products.slide03,
    products.slide04,
    products.slide05,
    products.slide06,
    // products.slide07,
  ],
  CO: [
    products.slide01,
    products.slide02,
    products.slide03,
    products.slide05,
    // products.slide07
  ],
  CL: [
    products.slide01,
    products.slide07,
    products.slide08,
    // products.slide07
  ],
  PE: [
    products.slide01,
    products.slide02,
    products.slide03,
    // products.slide07
  ],
};
