export const partners = [
  {
    path: '01_socios_OAK.svg',
    href: 'https://www.oakhcft.com/portfolio',
  },
  {
    path: '02_socios_Kaszek.svg',
    href: 'https://www.kaszek.com/companies/',
  },
  {
    path: '03_socios_500.svg',
    href: 'https://latam.500.co/companies',
  },
  {
    path: '04_socios_Y.svg',
    href: 'https://www.ycombinator.com/companies/99minutos',
  },
  {
    path: '05_socios_endeavor.svg',
    href: 'https://mexico.endeavor.org/',
  },
  {
    path: '06_socios_prosus.svg',
    href: 'https://www.prosus.com/our-portfolio',
  },
];
