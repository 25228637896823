import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';

import { varFade } from '../animate';

interface ScrollDotsProps {
  quantity: number;
  activeDot: number;
  scrollTo: (id: number) => void;
}

const ScrollDots = ({ quantity, activeDot, scrollTo }: ScrollDotsProps) => {
  const dots = Array.from({ length: quantity }, (_, i) => i);

  return (
    <m.div
      initial="initial"
      whileInView="animate"
      variants={varFade().inAndOut}
      style={{
        position: 'sticky',
        top: '50%',
        transform: 'translateY(-40%) translateX(-6.5rem)',
        marginTop: '12.5rem',
        paddingTop: '6.5rem',
        paddingBottom: '6.5rem',
      }}
      viewport={{ amount: 0.9 }}
    >
      <List>
        {dots.map((_, index) => (
          <Dot
            key={index}
            className={index === activeDot ? 'active' : ''}
            onClick={() => scrollTo(index)}
          />
        ))}
      </List>
    </m.div>
  );
};

const Dot = styled('li')(({ theme }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  margin: '10px',
  backgroundColor: theme.palette.secondary.main,
  cursor: 'pointer',
  transition: 'all 0.3s ease',

  '&.active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default ScrollDots;
